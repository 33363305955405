import React, { useState } from "react";
import emailjs from "emailjs-com";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";

const ContactForm = () => {
  // State for form fields
  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false); // Loading state for button
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage", // Add custom class for error messages
    })
  );

  // Handle input changes
  const changeHandler = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const submitHandler = (e) => {
    e.preventDefault();

    // Validate the form
    if (validator.allValid()) {
      setLoading(true); // Start loading
      validator.hideMessages(); // Hide error messages

      // Send email using EmailJS
      emailjs
        .send(
          "service_v3bi3ga", // Replace with your EmailJS service ID
          "template_nv0pf1i", // Replace with your EmailJS template ID
          {
            name: state.name,
            email: state.email,
            message: state.message,
          },
          "e3eRG96Nn7Aj2YpUZ" // Replace with your EmailJS user ID
        )
        .then(
          () => {
            // On success
            toast.success("Message sent successfully!");
            setState({ name: "", email: "", message: "" }); // Reset form
            setLoading(false); // Stop loading
          },
          (error) => {
            // On error
            toast.error("Failed to send message. Please try again later.");
            console.error(error);
            setLoading(false); // Stop loading
          }
        );
    } else {
      validator.showMessages(); // Show validation errors
      toast.error("Please fill in all required fields.");
    }
  };

  return (
    <form onSubmit={submitHandler} className="form">
      <div className="row">
        {/* Name Field */}
        <div className="col-lg-6 col-md-6 col-12">
          <div className="form-field">
            <input
              value={state.name}
              onChange={changeHandler}
              type="text"
              name="name"
              placeholder="Name"
            />
            <p>{validator.message("name", state.name, "required")}</p>
          </div>
        </div>

        {/* Email Field */}
        <div className="col-lg-6 col-md-6 col-12">
          <div className="form-field">
            <input
              value={state.email}
              onChange={changeHandler}
              type="email"
              name="email"
              placeholder="Email"
            />
            <p>{validator.message("email", state.email, "required|email")}</p>
          </div>
        </div>

        {/* Message Field */}
        <div className="col-lg-12">
          <div className="form-field">
            <textarea
              name="message"
              value={state.message}
              onChange={changeHandler}
              placeholder="Message"
            ></textarea>
            <p>{validator.message("message", state.message, "required")}</p>
          </div>
        </div>

        {/* Submit Button */}
        <div className="col-lg-12">
          <div className="form-submit">
            <button
              type="submit"
              disabled={loading}
              className="btn btn_primary text-uppercase mt-5"
            >
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </>
              ) : (
                "Send Message"
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
